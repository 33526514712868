import PageContainer from "../../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import Button from "../../../components/button/Button";
import {useNavigate} from "react-router";
import professoresApi from "../../../services/professoresApi";
import cursosApi from "../../../services/cursosApi";
import InputCurrency from "../../../components/inputCurrency";
import valoresCursoApi from "../../../services/valoresCursoApi";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

export default function TabelaValoresCursos(){
    const navigate = useNavigate();
    const [list, setlist] = useState([]);
    /*const data = [
        {
            "categoria_id": 1,
            "categoria": "ARTES",
            "idades": [
                {
                    "idade": 16,
                    "salas": [
                        {
                            "nome": "SL1",
                            "curso": {
                                "curso_id": 1,
                                "nome": "Curso tal",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }
                        },
                        {
                            "nome": "SL2",
                            "curso": {
                                "curso_id": 2,
                                "nome": "Curso dança",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }

                        },
                        {
                            "nome": "SL3",
                            "curso": {
                                "curso_id": 2,
                                "nome": "Curso dança",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }

                        },
                        {
                            "nome": "SL4",
                            "curso": {
                                "curso_id": 2,
                                "nome": "Curso dança",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }

                        }
                    ]
                },{
                    "idade": 12,
                    "salas": [
                        {
                            "nome": "SL1",
                            "curso": {
                                "curso_id": 1,
                                "nome": "Curso tal",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }
                        },
                        {
                            "nome": "SL2",
                            "curso": {
                                "curso_id": 2,
                                "nome": "Curso dança",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }

                        }
                    ]
                },{
                    "idade": 10,
                    "salas": [
                        {
                            "nome": "SL1",
                            "curso": {
                                "curso_id": 1,
                                "nome": "Curso tal",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }
                        },
                        {
                            "nome": "SL2",
                            "curso": {
                                "curso_id": 2,
                                "nome": "Curso dança",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }

                        }
                    ]
                }
            ]
        },{
            "categoria_id": 1,
            "categoria": "ADULTO",
            "idades": [
                {
                    "idade": 18,
                    "salas": [
                        {
                            "nome": "SL1",
                            "curso": {
                                "curso_id": 1,
                                "nome": "Curso tal",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }
                        },
                        {
                            "nome": "SL2",
                            "curso": {
                                "curso_id": 2,
                                "nome": "Curso dança",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }

                        }
                    ]
                },{
                    "idade": 16,
                    "salas": [
                        {
                            "nome": "SL1",
                            "curso": {
                                "curso_id": 1,
                                "nome": "Curso tal",
                                "valores": {
                                    "mensal": "300.00",
                                    "trimestral": "300.00",
                                    "semestral": "300.00",
                                    "avulsa": "300.00"
                                }
                            }
                        }
                    ]
                }
            ]
        }
    ];*/

    async function loadData() {
        const { data, isError } = await cursosApi.valoresCursos();
        setlist(data)
    }

    useEffect(() => { loadData() }, [])

    const onChange = (value: any, name: string, indexList: number, indexIdades: number, indexSalas: number) => {
        let newList: any = [...list];
        newList[indexList]['idades'][indexIdades]['salas'][indexSalas]['curso']['valores'][name] = value;
        setlist(newList);
    }

    const salvaValor = async (curso_id: number, valor: any, field: string) => {
        //console.log(curso_id, valor);

        const {data, isError} = await valoresCursoApi.criarValorUnico({curso_id: curso_id, valor: valor, coluna: field});

    }

    return(
        <PageContainer>
            <div className={'flex flex-col'}>
                <div className={'flex flex-row  p-2 w-full'}>
                    <Button className={'bg-conhecimento hover:bg-conhecimento-100 text-white'} onClick={() => navigate('/cursos')}>
                        Tabela padrão
                    </Button>
                </div>
            </div>
            <div className={'mt-5 w-full flex flex-row justify-end'}>
                <div className={'flex w-[90%] flex-col mb-1 '}>
                    <div className={'grid grid-cols-12 gap-x-2 gap-y-2'}>
                        <div className={'col-span-4 px-3'}>

                        </div>
                        <div className={'col-span-4 px-3'}>

                        </div>

                        <div className={'col-span-1  border border-2 p-2 rounded-md'}>
                            <div className={'h-full flex justify-center'}>
                                Mensal
                            </div>
                        </div>
                        <div className={'col-span-1 border border-2 p-2 rounded-md '}>
                            <div className={'h-full flex justify-center'}>
                                Trimestral
                            </div>
                        </div>
                        <div className={'col-span-1  border border-2 p-2 rounded-md'}>
                            <div className={'h-full flex justify-center'}>
                                Semestral
                            </div>
                        </div>
                        <div className={'col-span-1  border border-2 p-2 rounded-md'}>
                            <div className={'h-full flex justify-center'}>
                                Avulsa
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={'flex flex-col justify-self-center'}>

                {
                    list.map((dt: any, indexList: number) => {
                        return(
                            <div className={'flex flex-row mb-1'}>
                                <div className={'flex border rounded-md justify-center items-center w-[10rem] border-2 px-3 mr-1 '}>
                                    {dt.categoria}
                                </div>
                                <div className={'flex flex-col'}>
                                    {dt.idades.map((idade: any, indexIdades: number) => {
                                        return(
                                            <div className={'flex flex-row'}>
                                                <div className={'flex border border-2 rounded-md px-3 mr-1 w-[4rem] items-center justify-center'}>
                                                    {idade.idade}
                                                </div>
                                                <div className={'flex flex-col'}>
                                                    {
                                                        idade.salas.map((sala: any, indexSalas: number) =>{
                                                            return(
                                                                <div className={'grid grid-cols-12 gap-x-2'}>
                                                                    <div className={'col-span-2  items-center border border-2 px-3 text-center rounded-md'}>
                                                                        <div className={'h-full flex justify-center items-center'}>{sala.nome}</div>
                                                                    </div>
                                                                    {/*<div className={'col-span-1 border border-2 px-3 '}>
                                                                        i
                                                                    </div>*/}
                                                                    <div className={'col-span-6 border border-2 px-3 rounded-md'}>
                                                                        <div className={'h-full flex items-center'}>
                                                                            {sala.curso.nome}
                                                                        </div>
                                                                    </div>
                                                                    <div className={'col-span-1 border border-2 rounded-md'}>
                                                                        <InputCurrency
                                                                            value={sala.curso.valores.mensal}
                                                                            onChange={
                                                                                (value: any, name: any) =>onChange(value, 'mensal', indexList, indexIdades, indexSalas)
                                                                            }
                                                                            onBlur={() => salvaValor(sala.curso.curso_id, sala.curso.valores.mensal, 'mensal')}
                                                                        />
                                                                    </div>
                                                                    <div className={'col-span-1 border border-2 rounded-md'}>
                                                                        <InputCurrency
                                                                            value={sala.curso.valores.trimestral}
                                                                            onChange={(value: any, name: any) =>onChange(value, 'trimestral', indexList, indexIdades, indexSalas)}
                                                                            onBlur={() => salvaValor(sala.curso.curso_id, sala.curso.valores.trimestral, 'trimestral')}
                                                                        />
                                                                    </div>
                                                                    <div className={'col-span-1 border border-2 rounded-md'}>
                                                                        <InputCurrency
                                                                            value={sala.curso.valores.semestral}
                                                                            onChange={(value: any, name: any) =>onChange(value, 'semestral', indexList, indexIdades, indexSalas)}
                                                                            onBlur={() => salvaValor(sala.curso.curso_id, sala.curso.valores.semestral, 'semestral')}
                                                                        />
                                                                    </div>
                                                                    <div className={'col-span-1 border border-2 rounded-md'}>
                                                                        <InputCurrency
                                                                            value={sala.curso.valores.avulsa}
                                                                            onChange={(value: any, name: any) =>onChange(value, 'avulsa', indexList, indexIdades, indexSalas)}
                                                                            onBlur={() => salvaValor(sala.curso.curso_id, sala.curso.valores.avulsa, 'avulso')}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </PageContainer>
    );
}