/* eslint-disable import/no-anonymous-default-export */
import { Aluno } from '../models';
import api, { makeCrudObject } from './api';

export default {
  ...makeCrudObject<Aluno>('alunos'),
  alunoCurso: (form: any) => {
    return api.request({ url: 'alunos/cursos/' + form.id, method: 'POST', data: form });
  },
  
  sendContract: (alunoId: any, matricula: any, form: any) => {
    return api.request({ url: `alunos/cursos/${alunoId}/${matricula}/contract/send`, method: 'POST', data: form });
  },

  alunoCursoFind: (id: any, matricula: any) => {
    return api.request({ url: `alunos/cursos/${id}/${matricula}`, method: 'GET'});
  },
  alunoCursoList: (data: any) => {
    return api.request({ url: 'alunos/lista/cursos', method: 'GET', params: data });
  },
  alunoList: (query: any) => {
    return api.request({ url: 'alunos/lista' + (query?.query?.date ? `?date=${query.query?.date}` : ''), method: 'GET'});
  },
  definirPagamento: (data: any) => {
    return api.request({ url: 'alunos/definir-pago', method: 'POST', data: data });
  },

  downloadSigned: (envelopeId: string) => {
    return api.request({ url:`alunos/contrato/${envelopeId}/download`, method: 'GET' });
  },

  downloadPreview: (data: any) => {
    return api.request({ url:`alunos/contrato`, method: 'POST', data: data });
  },
  searchByResponsavelCpf(cpf: string) {
    return api.request<Aluno[]>({ url: `alunos/search/responsavel-cpf/${cpf}`, method: 'GET' });
  },
};
