import React, {useEffect, useState} from "react";
import PageContainer from "../../../components/container/PageContainer";
import Button from "../../../components/button/Button";
import {useNavigate} from "react-router";
import Form from "../../../components/form/Form";
import Input from "../../../components/input/Input";
import InputSelect from "../../../components/inputSelect";
import {ICategoria, IMateriais, IProfessor, ISelect} from "../../../types";
import categoriaApi from "../../../services/categoriaApi";
import SelectDias from "../../../components/selectDias";
import turnosApi from "../../../services/turnosApi";
import TurnosComponent from "../../../components/turnosComponent";
import DiasTurnosComponent from "../../../components/diasTurnosComponent";

import materiaisApi from "../../../services/materiaisApi";
import CurrencyInput from 'react-currency-input-field';
import Str from "../../../helpers/Str";
import InputCurrency from "../../../components/inputCurrency";
import { FiPlusCircle } from "react-icons/fi";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { FiClock } from "react-icons/fi";
import { HiUserGroup } from "react-icons/hi2";
import { FaRegPlayCircle } from "react-icons/fa";
import {MdOutlineAddCircleOutline, MdOutlinePauseCircle} from "react-icons/md";
import { IoIosRemoveCircleOutline } from "react-icons/io";

import useModal from "../../../hooks/useModal";
import Modal from "../../../components/modal/Modal";
import CardButton from "../../../components/cardButton";
import Obj from "../../../helpers/Obj";
import {useParams} from "react-router-dom";
import Alert from "../../../helpers/Alert";
import professoresApi from "../../../services/professoresApi";
import diasApi from "../../../services/diasApi";
import cursosApi from "../../../services/cursosApi";
export default function FormularioProfessor() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const id = params.id;
    const defaltMateriaisData = {
        material: {},
        valor: null,
        repasse: {}
    };
    const [state, setState] = useState<IProfessor>({
        'nome': '',
        'data_nascimento': '',
        'endereco': '',
        'cnpj': '',
        'tipo_chave_pix': '',
        'pix': '',
        'inicio': '',
        'termino': '',
        //'quantidade_cursos': 0,
        'quantidade_cursos': 1,
        'idade_aulas': '',
        'cursos': [],
        'disponibilidade_professor': [],
        'disponibilidade_oficina': [],
        'oficina': false,
        'incluir_material_oficina': false,
        'incluir_material': false,
        'materiais': [{
            material: {},
            valor: null,
            repasse: {}
        }],
        'materiais_oficinas': [{
            material: {},
            valor: null,
            repasse: {}
        }],
    });

    const { modalProps, open, close } = useModal();

    const [materiaisOficinas, setMateriaisOficinas]: any[] = useState([defaltMateriaisData])

    const [pagina, setPagina] = useState(1);
    const [categoriasOptions, setCategoriasOptions] = useState<any>([]);
    const [categoriasSelected, setCategoriasSelected] = useState<any>([]);
    const [categorias, setCategorias] = useState<any>([]);

    const [cursosOptions, setCursosOptions] = useState<any>([]);
    const [cursosSelected, setCursosSelected] = useState<any>([]);
    const [cursos, setCursos] = useState<any>([]);

    const [selectedDias, setSelectedDias] = useState([]);
    const [turnosDias, setTurnosDias]: any[] = useState([]);

    const [optionsTurnos, setOptionsTurnos] = useState<any>();

    const [selectedDiasOficina, setSelectedDiasOficina] = useState([]);
    const [turnosDiasOficina, setTurnosDiasOficina]: any[] = useState([]);

    const [materiaisOptions, setMateriaisOptions]: any[] = useState();
    const [materiais, setMateriais]: any[] = useState();
    const dias = Obj.copy(diasApi);


    const optionsPix = [
        {
            label: 'Celular',
            value: 'celular'
        },
        {
            label: 'E-mail',
            value: 'email'
        },
        {
            label : 'CPF',
            value: 'cpf'
        },
        {
            label: 'CNPJ',
            value: 'cnpj'
        },
        {
            label: 'Chave aleatória',
            value: 'chave'
        }
    ];

    const optionsCursos = [
        {
            label: '1',
            value: 1
        },
        {
            label: '2',
            value: 2
        },
        {
            label: '3',
            value: 3
        },
        {
            label: '4',
            value: 4
        },
    ]

    const optRepasse = [
        {
            value: 'espaco',
            label: 'Espaço'
        },
        {
            value: 'professor',
            label: 'Professor'
        },
    ];

    const optIdade = () => {
        let opt = [];
        for (var i = 4; i <= 80; i++) {
            opt.push({
                label: i,
                value: i
            },)
        }
        return opt;
    }

    function handleChange(value: any, name: string) {
        setState({ ...state, [name]: value });
    }

    function handleChangeMateriaisOficina(value: any, name: any, key: number) {
        const newmatOf = [...materiaisOficinas];
        if (name === 'material') {
            const materiaisFilter = [...materiais];
            const mat = materiaisFilter.filter((mt: any) => mt.id === value.value);
            newmatOf[key]['valor'] = mat[0].valor;
        }
        newmatOf[key][name] = value;

        setMateriaisOficinas(newmatOf);
    }

    function handleChangeMateriaisProfessor(value: any, name: any, key: number) {
        const newMat = [...state['materiais']];
        if (name === 'material') {
            const mat = materiais.filter((mt: any) => mt.id === value.value);
            newMat[key]['valor'] = mat[0].valor;
        }
        newMat[key][name] = value;
        setState({...state, 'materiais': newMat})
    }

    function handleChangeHorarios(value: any, name: string, index: number, arrayPrimarioIndex: number) {
        const newArraySelectedDias = [...turnosDias];
        const turno = Obj.copy(newArraySelectedDias[arrayPrimarioIndex]['turnos'][index]);
        turno[name] = value;
        newArraySelectedDias[arrayPrimarioIndex]['turnos'][index] = {...turno};
        setTurnosDias(newArraySelectedDias);
    }

    async function getData(){
        setLoading(true);
        Alert.await(`Carregando dados...`);
        const [cursosData, turnosData, materiaisData, categoriaData] = await  Promise.all([
            cursosApi.listAll(),
            turnosApi.listAll(),
            materiaisApi.listAll(),
            categoriaApi.listAll(),
        ]);

        if (!categoriaData.isError){
            setCategorias(categoriaData.data);
            const optCurso = cursosData.data.map((opt: any) => {
                return {
                    label: opt.descricao,
                    value: opt.id
                }
            });
            setCursosOptions(optCurso);
        }

        if (!cursosData.isError){
            setCursos(cursosData.data);
            const optCurso = cursosData.data.map((opt: any) => {
                return {
                    label: opt.descricao,
                    value: opt.id
                }
            });
            setCursosOptions(optCurso);
        }

        if (!turnosData.isError){

            const optTurnos = turnosData.data.map((trDt: any) => {
                return { value: trDt.id, label: trDt.nome === 'Todos' ? trDt.nome : trDt.nome.substring(3, -5) }
            });
            setOptionsTurnos(optTurnos);
        }

        if (!materiaisData.isError){
            setMateriais(materiaisData.data);
            const optMaterias = materiaisData.data.map((mtD: IMateriais) => {
                return {
                    label: mtD.nome,
                    value: mtD.id
                }
            });
            setMateriaisOptions(optMaterias);
        }

        if (id){
            const params: any = [];
            params['relations'] = 'cursos,materiais,disponibilidade,professorOficina';
            const { data, isError } = await professoresApi.one(id, params)
            loadDataProfessor(data);
        }
        Alert.close();
        setLoading(false);
    }

    const loadDataProfessor = (data: any) => {
        setCursosSelected(data.cursos.map((ct: any) => {return {value: ct.id, label: ct.descricao}}));
        let diasData: any = [];
        setSelectedDias(diasData);

        let disponibilidadeData: any = [];
        data.disponibilidade.map((disp: any) => {
            diasData.push(disp.dia);
            const diaTurnoData = [...dias].find((ds: any) => ds.id === disp.dia);
            let newTurno: any = [];
            disp.disponibilidade_turnos.map((dpsT: any) => {
                newTurno.push({
                    "value": dpsT.id,
                    "label": dpsT.nome.substring(3, -5),
                    "quantidade_alunos": dpsT.pivot.quantidade_alunos,
                    "duracao": dpsT.pivot.duracao,
                    "inicio_aula": dpsT.pivot.inicio_aula,
                    "final_aula": dpsT.pivot.final_aula
                })
            });
            diaTurnoData.turnos = newTurno
            disponibilidadeData.push(diaTurnoData);
        });
        setTurnosDias(disponibilidadeData);


        //oficina
        if (data.oficina && data.professor_oficina){
            const oficina_disponibilidades = data.professor_oficina?.disponibilidade;
            const oficina_materiais = data.professor_oficina?.materiais;

            const oficinaDias: any = [];
            let disponibilidade_data_oficina: any = [];

            oficina_disponibilidades && oficina_disponibilidades.map((po: any) => {
                oficinaDias.push(po.dia)
                const diaTurnoData = [...dias].find((ds: any) => ds.id === po.dia);
                const new_oficina_disponibilidade: any = [];
                po.disponibilidade_turnos.map((dpsT: any) => {
                    new_oficina_disponibilidade.push({
                        "value": dpsT.id,
                        "label": dpsT.nome.substring(3, -5),
                    })
                });
                diaTurnoData.turnos = new_oficina_disponibilidade;
                disponibilidade_data_oficina.push(diaTurnoData);

            });
            setTurnosDiasOficina(disponibilidade_data_oficina);

            setSelectedDiasOficina(oficinaDias);

            const materiais_oficina: any = [];
            oficina_materiais.map((ofm: any) => {
                materiais_oficina.push({
                    "material": {value: ofm.id, label: ofm.nome },
                    "valor": ofm.pivot.valor,
                "repasse": optRepasse.find((opt: any) => opt.value === (ofm.pivot.repasse_id ? 'professor' : 'espaco'))
                })
            });
            setMateriaisOficinas(materiais_oficina);
        }

        const materiais_professor: any = [];

        data.materiais.map((mt: any) => {
            materiais_professor.push({
                "material": {value: mt.id, label: mt.nome },
                "valor": mt.pivot.valor,
                "repasse": optRepasse.find((opt: any) => opt.value === (mt.pivot.repasse_id ? 'professor' : 'espaco'))
            })
        })
        const dataState = {
            'nome': data.nome,
            'data_nascimento': data.data_nascimento,
            'endereco': data.endereco,
            'cnpj': data.cnpj,
            'pix': data.pix,
            'inicio': data.inicio,
            'termino': data.termino,
            'quantidade_cursos': {value: data.quantidade_cursos, label: data.quantidade_cursos},
            'idade_aulas': {value: data.idade_aulas, label: data.idade_aulas},
            'tipo_chave_pix': optionsPix.find((opt: any) => opt.value === data.tipo_chave_pix),
            'oficina': data.oficina,
            'incluir_material_oficina': data.incluir_material_oficina,
            'incluir_material': data.incluir_material,
            'materiais' : materiais_professor,
        };
        setState(dataState);
    }

    useEffect(() => {
        getData();
    }, [])

    function onSelectCursos (values: [ISelect]){
        setLoading(true);
        setCursosSelected(values);
        setLoading(false);
    }

    const onSelectDias = (e: any) => {
        setSelectedDias(e);
    }

    const addNovoMaterialOficina = () => {
        const novoDado = [...materiaisOficinas];
        novoDado.push(defaltMateriaisData);
        setMateriaisOficinas(novoDado);
    }

    const addNovoMaterialProfessor = () => {
        const novoDado = [...state['materiais']];
        novoDado.push(defaltMateriaisData);
        setState({...state, 'materiais': novoDado})
    }

    const removerMaterial = (key: number) => {

        const novoDado = [...materiaisOficinas];
        delete novoDado[key];
        const arraySemVazios = novoDado.filter(function (i) {
            return i;
        });
        setMateriaisOficinas(arraySemVazios);

    }

    const removerMaterialProf = (key: number) => {
        const novoDado = [...state['materiais']];
        delete novoDado[key];
        const arraySemVazios = novoDado.filter(function (i) {
            return i;
        });
        setState({...state, 'materiais': arraySemVazios})
    }

    const onSubmit = async () => {
        let stateData = {...state};
        //const idsCat = categoriasSelected.map((data: any) => (data.value));
        const idsCursos = cursosSelected.map((data: any) => (data.value));
        let disponibilidade_professor: { id: any; name: string; turno: never[]; }[] = [];
        let disponibilidade_oficina: { id: any; name: string; turno: never[]; }[] = [];
        turnosDias.map((dt: any) => {
            disponibilidade_professor.push(returnArrayDiasTurnos(dt));
        });
        turnosDiasOficina.map((dt: any) => {
            disponibilidade_oficina.push(returnArrayDiasTurnos(dt));
        });
        //stateData.categorias = idsCat;
        stateData.cursos = idsCursos;
        stateData.quantidade_cursos = stateData.quantidade_cursos.value;
        stateData.idade_aulas = stateData.idade_aulas ? stateData.idade_aulas.value : null;
        stateData.disponibilidade_professor = disponibilidade_professor;
        stateData.disponibilidade_oficina = disponibilidade_oficina;
        stateData.tipo_chave_pix = stateData.tipo_chave_pix ? stateData.tipo_chave_pix.value : null;

        stateData.materiais_oficinas = materiaisOficinas;
        Alert.await(`Salvando dados`);
        const {
            data,
            isError
        } = id ? await professoresApi.update(id, stateData) : await professoresApi.create(stateData);
        Alert.close();
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        const mensage = id ? "Editado com sucesso!" : "Cadastrado com sucesso!"
        Alert.success(mensage);
        setTimeout(() => {
            navigate('/professores');
        }, 3000);
    }

    const returnArrayDiasTurnos = (dt: any) =>{
        let retorno = {
            id: dt.id,
            name: dt.name,
            turno: [],
        }
        dt?.turnos.map((tr: any) => {
            tr.id = tr.value;
            // @ts-ignore
            retorno.turno.push(tr);
        });
        return retorno;
    }

    const addHorarioTurnosProfessor = (indexTurnosDias: number, indexTurnos: number) => {
        const dataTurnosDias = [...turnosDias];
        const copNewValue = dataTurnosDias[indexTurnosDias].turnos[indexTurnos];
        dataTurnosDias[indexTurnosDias].turnos.push(copNewValue);
        setTurnosDias(dataTurnosDias);

    }
    const removeHorarioTurnosProfessor = (indexTurnosDias: number, indexTurnos: number) => {
        const dataTurnosDias = [...turnosDias];
        const dataFilter = dataTurnosDias[indexTurnosDias].turnos.filter((tr: any, index: number) => index !== indexTurnos)
        dataTurnosDias[indexTurnosDias].turnos = dataFilter;
        setTurnosDias(dataTurnosDias);

    }

    const onSelectTurnos = (e: any, tr: any, dt: any, index: number, indexPrimario: number) => {
        setTurnosDias((currentTurnosDias: any[]) => {
            return currentTurnosDias.map((turnosDias: any) => {
                if (turnosDias.id !== dt.id) return turnosDias;
                turnosDias.turnos[index] = {
                    ...turnosDias.turnos[index],
                    label: e.label,
                    value: e.value
                };
                return turnosDias;
            });
        });
    }

    return(
        <>
            <PageContainer>
                <div className={'flex justify-center mt-5 lg:mb-[30px]'}>
                    <div className={'flex flex-col lg:w-[70rem] min-[320px]:w-auto h-full border p-5'}>
                        <div className={'flex flex-row justify-between p-2 w-full'}>
                            <h1 className={'text-3xl flex items-center'}>Professores</h1>
                            <div className={'flex'}>
                                {
                                    selectedDias.length && pagina === 1 ?
                                        <div className={'flex mr-5'}>
                                            <button title={'Horarios'} onClick={() => open()} >
                                                <FiClock size={60} />
                                            </button>
                                        </div>
                                        :
                                        <></>
                                }


                                <Button className={'h-[3.2rem] mt-[0.5rem] bg-conhecimento hover:bg-conhecimento-100 text-white'} onClick={() => pagina === 1 ? navigate('/professores') : setPagina(1) }>Voltar</Button>
                            </div>
                        </div>

                        <Form className={'flex flex-col w-[100%]'} onSubmit={()=>onSubmit()}>
                            {
                                pagina === 1 ?
                                    <div>
                                        <div className={'flex lg:flex-row min-[320px]:flex-col w-[100%]'}>
                                            <div className={'lg:w-[50%] min-[320px]:w-[100%] lg:p-5 min-[320px]:p-1'}>
                                                <div className={'text-2xl'}>
                                                    Cadastro - Geral
                                                </div>
                                                <div className="grid grid-cols-1 mt-3">
                                                    <Input
                                                        label="Nome"
                                                        className="col-span-1"
                                                        value={state?.nome}
                                                        setValue={v => handleChange(v, 'nome')}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-1 mt-3">
                                                    <Input
                                                        type={"date"}
                                                        label="Data nascimento"
                                                        className="col-span-1"
                                                        value={state?.data_nascimento}
                                                        setValue={v => handleChange(v, 'data_nascimento')}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-1 mt-3">
                                                    <Input
                                                        label="Endereço"
                                                        className="col-span-1"
                                                        value={state?.endereco}
                                                        setValue={v => handleChange(v, 'endereco')}
                                                    />
                                                </div>
                                                <div className="grid lg:grid-cols-3 sm:grid-cols-1 min-[320px]:grid-cols-1 gap-4">
                                                    <div className={'grid grid-cols-1'}>
                                                        <InputSelect
                                                            selected={state.tipo_chave_pix}
                                                            loading={false}
                                                            options={optionsPix}
                                                            onChangeSelect={(e: any) => handleChange(e, 'tipo_chave_pix')}
                                                            placeholder={'Chave pix'}

                                                        />
                                                    </div>
                                                    <Input
                                                        className="lg:col-span-2 sm:col-span-1 min-[320px]:col-span-1"
                                                        value={state?.pix}
                                                        setValue={v => handleChange(v, 'pix')}
                                                        placeholder={'Pix'}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-1 mt-3">
                                                    <Input
                                                        label="CNPJ"
                                                        mask={'CNPJ'}
                                                        className="col-span-1"
                                                        value={state?.cnpj}
                                                        setValue={v => handleChange(v, 'cnpj')}
                                                    />
                                                </div>
                                                <div className="grid lg:grid-cols-2 sm:grid-cols-1 min-[320px]:grid-cols-1 mt-3 gap-4">

                                                    <Input
                                                        type={"date"}
                                                        label="Início"
                                                        className="col-span-1"
                                                        value={state?.inicio}
                                                        setValue={v => handleChange(v, 'inicio')}
                                                    />

                                                    <Input
                                                        type={"date"}
                                                        label="Termino"
                                                        className="col-span-1"
                                                        value={state?.termino}
                                                        setValue={v => handleChange(v, 'termino')}
                                                    />
                                                </div>

                                            </div>
                                            <div className={'lg:w-[50%] min-[320px]:w-[100%] lg:p-5 min-[320px]:p-1'}>
                                                <div className={'text-2xl'}>
                                                    Informações
                                                </div>
                                                <div className="grid lg:grid-cols-5  gap-4">

                                                    {/* <div className={'grid col-span-1 lg:mt-[2rem]'}>
                                                        Modalidades
                                                    </div>
                                                    <div className={'grid grid-span-1'}>
                                                        <InputSelect
                                                            selected={state.quantidade_cursos}
                                                            loading={false}
                                                            options={optionsCursos}
                                                            onChangeSelect={(e: any) => handleChange(e, 'quantidade_cursos')}
                                                            placeholder={''}
                                                        />
                                                    </div> */}

                                                    <div className={'grid lg:col-span-5 mt-3'}>
                                                        Idade
                                                    </div>
                                                    <div className={'lg:col-span-5 -mt-10'}>
                                                        <InputSelect
                                                            selected={state.idade_aulas}
                                                            loading={false}
                                                            options={optIdade()}
                                                            onChangeSelect={(e: any) => handleChange(e, 'idade_aulas')}
                                                            placeholder={''}
                                                            className="w-full"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 mt-3">
                                                    <div className={'grid grid-span-1'}>
                                                        <InputSelect
                                                            label={'Cursos'}
                                                            selected={cursosSelected}
                                                            loading={loading}
                                                            options={cursosOptions}
                                                            onChangeSelect={(e: any) => onSelectCursos(e)}
                                                            placeholder={'Seleciones os Cursos'}
                                                            isMulti={true}
                                                        />
                                                    </div>
                                                </div>

                                                <DiasTurnosComponent
                                                    mult={true}
                                                    selectedDias={selectedDias}
                                                    onSelectDias={onSelectDias}
                                                    optionsTurnos={optionsTurnos}
                                                    arraySelectedDias={turnosDias}
                                                    setArraySelectedDias={setTurnosDias}
                                                />
                                            </div>
                                        </div>
                                        <div className={'flex w-[100%] justify-end p-2'}>
                                            <button
                                                onClick={() => setPagina(2)}
                                                type={'button'}
                                                className={Str.tw(
                                                    'bg-constancia border rounded-md w-max min-w-[117px] p-3',
                                                    'transition hover:bg-constancia-100 active:border-primary',
                                                    'disabled:opacity-70 disabled:pointer-events-none',
                                                    'font-bold text-white'
                                                )}
                                            >Próximo</button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className={'flex lg:flex-row min-[320px]:flex-col w-[100%]'}>
                                            <div className={'lg:w-[50%] min-[320px]:w-[100%] lg:p-5 min-[320px]:p-1'}>
                                                <div className="flex items-center mt-4">
                                                    <input
                                                        onChange={() => handleChange(!state.oficina, 'oficina')}
                                                        checked={state.oficina}
                                                        id="checked-checkbox"
                                                        type="checkbox"
                                                        value=""
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    />
                                                    <label
                                                        htmlFor="checked-checkbox"
                                                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Oficina
                                                    </label>
                                                </div>
                                                {
                                                    state.oficina ?

                                                        <>
                                                            <div>
                                                                <DiasTurnosComponent
                                                                    selectedDias={selectedDiasOficina}
                                                                    onSelectDias={setSelectedDiasOficina}
                                                                    optionsTurnos={optionsTurnos}
                                                                    arraySelectedDias={turnosDiasOficina}
                                                                    setArraySelectedDias={setTurnosDiasOficina}
                                                                />
                                                            </div>
                                                            <div className="flex  justify-between mt-4">
                                                                <div className={'flex items-center mt-4'}>
                                                                    <input
                                                                        onChange={() => handleChange(!state.incluir_material_oficina, 'incluir_material_oficina')}
                                                                        checked={state.incluir_material_oficina}
                                                                        id="checked-checkbox-2"
                                                                        type="checkbox"
                                                                        value=""
                                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                    />
                                                                    <label
                                                                        htmlFor="checked-checkbox-2"
                                                                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                                    >
                                                                        Incluir materiais oficina?
                                                                    </label>
                                                                </div>
                                                                {
                                                                    state.incluir_material_oficina ?
                                                                        <div className={'mt-3'}>
                                                                            <button type={'button'} title="Adicionar" onClick={() => addNovoMaterialOficina()}>
                                                                                <FiPlusCircle color={'#30694A'} size={30}/>
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }

                                                            </div>
                                                            {
                                                                state.incluir_material_oficina ?
                                                                    materiaisOficinas.length ?
                                                                        materiaisOficinas.map((mtOf: any, index: any) => {
                                                                            return(
                                                                                <>
                                                                                    <div className="grid mt-5">
                                                                                        {
                                                                                            index ?
                                                                                                <div className={'grid justify-items-end'}>
                                                                                                    <button type={'button'} title="Remover" onClick={() => removerMaterial(index)}>
                                                                                                        <IoMdRemoveCircleOutline  color={'#b42018'} size={20}/>
                                                                                                    </button>
                                                                                                </div>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                        <div className="grid grid-cols-4 gap-4">
                                                                                            <InputSelect
                                                                                                label={'Material'}
                                                                                                classNameContainer={'col-span-2'}
                                                                                                selected={mtOf.material}
                                                                                                loading={false}
                                                                                                options={materiaisOptions}
                                                                                                onChangeSelect={(e: any) => handleChangeMateriaisOficina(e, 'material', index)}
                                                                                                placeholder={'Material'}
                                                                                            />
                                                                                            <InputCurrency
                                                                                                label={'Valor'}
                                                                                                value={mtOf.valor}
                                                                                                onChange={(value: any, name: any) =>handleChangeMateriaisOficina(value, 'valor', index)}
                                                                                            />
                                                                                            <InputSelect
                                                                                                label={'Repasse'}
                                                                                                classNameContainer={'col-span-1'}
                                                                                                selected={mtOf.repasse}
                                                                                                loading={false}
                                                                                                options={optRepasse}
                                                                                                onChangeSelect={(e: any) => handleChangeMateriaisOficina(e, 'repasse', index)}
                                                                                                placeholder={'Repasse'}
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                        :
                                                                        ''
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }

                                            </div>
                                            <div className={'lg:w-[50%] min-[320px]:w-[100%] lg:p-5 min-[320px]:p-1'}>
                                                <div className="flex  justify-between mt-4">
                                                    <div className={'flex items-center'}>
                                                        <input
                                                            onChange={() => handleChange(!state.incluir_material, 'incluir_material')}
                                                            checked={state.incluir_material}
                                                            id="checked-checkbox-3"
                                                            type="checkbox"
                                                            value=""
                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                        />
                                                        <label
                                                            htmlFor="checked-checkbox-3"
                                                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                        >
                                                            Incluir material?
                                                        </label>
                                                    </div>
                                                    {
                                                        state.incluir_material ?
                                                            <div>
                                                                <button type={'button'} title="Adicionar" onClick={() => addNovoMaterialProfessor()}>
                                                                    <FiPlusCircle color={'#30694A'} size={30}/>
                                                                </button>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                {
                                                    state.incluir_material ?
                                                        state.materiais.map((mat: any, index: any) =>{
                                                            return(
                                                                <>
                                                                    <div className="grid mt-5">
                                                                        {
                                                                            index ?
                                                                                <div className={'grid justify-items-end'}>
                                                                                    <button type={'button'} title="Remover" onClick={() => removerMaterialProf(index)}>
                                                                                        <IoMdRemoveCircleOutline  color={'#b42018'} size={20}/>
                                                                                    </button>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        <div className="grid grid-cols-4 gap-4">
                                                                            <InputSelect
                                                                                label={'Material Aula'}
                                                                                classNameContainer={'col-span-2'}
                                                                                selected={mat.material}
                                                                                loading={false}
                                                                                options={materiaisOptions}
                                                                                onChangeSelect={(e: any) => handleChangeMateriaisProfessor(e, 'material', index)}
                                                                                placeholder={'Material'}
                                                                            />
                                                                            <InputCurrency
                                                                                label={'Valor'}
                                                                                value={mat.valor}
                                                                                onChange={(value: any, name: any) =>handleChangeMateriaisProfessor(value, 'valor', index)}
                                                                            />
                                                                            <InputSelect
                                                                                label={'Repasse'}
                                                                                classNameContainer={'col-span-1'}
                                                                                selected={mat.repasse}
                                                                                loading={false}
                                                                                options={optRepasse}
                                                                                onChangeSelect={(e: any) => handleChangeMateriaisProfessor(e, 'repasse', index)}
                                                                                placeholder={'Repasse'}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        <></>
                                                }

                                            </div>
                                        </div>
                                        <div className={'flex w-[100%] justify-end p-2'}>
                                            <Button type={'submit'} className={'bg-constancia hover:bg-constancia-100 text-white'} >Concluir</Button>
                                        </div>
                                    </div>
                            }
                        </Form>
                    </div>
                </div>
            </PageContainer>
            <Modal size="lm" title="Horários" {...modalProps}>
                {
                    turnosDias.map((dt: any, indexPrimario: number) => {
                        return(
                            <>
                                <div className={`grid grid-cols-${indexPrimario === 0 ? '11' : '1'} gap-4 mt-4`}>


                                        <div className={'col-span-3'}>
                                            <div className={'flex w-[60px]'}>
                                                <CardButton text={dt?.abreviacao} onSelect={() => {
                                                    ""
                                                }}/>
                                            </div>
                                        </div>
                                        {
                                            indexPrimario === 0 &&
                                            <>
                                                <div className={'col-span-2'}>
                                                    <CardButton
                                                        text={<HiUserGroup size={20} />}
                                                        titleHtml={'Alunos/hora'}
                                                        onSelect={() => {
                                                            ""
                                                        }}
                                                    />
                                                </div>
                                                <div className={'col-span-2'}>
                                                    <CardButton
                                                        text={<FiClock size={20} />}
                                                        titleHtml={'Duração/aula'}
                                                        onSelect={() => {
                                                            ""
                                                        }}
                                                    />
                                                </div>
                                                <div className={'col-span-2'}>
                                                    <CardButton
                                                        text={<FaRegPlayCircle size={20} />}
                                                        titleHtml={'Início/aula'}
                                                        onSelect={() => {
                                                            ""
                                                        }}
                                                    />
                                                </div>
                                                <div className={'col-span-2'}>
                                                    <CardButton
                                                        text={<MdOutlinePauseCircle size={20} />}
                                                        titleHtml={'Final/aula'}
                                                        onSelect={() => {
                                                            ""
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }

                                </div>
                                {
                                    dt.turnos.length ? dt.turnos.map((tr: any, index: number) => {
                                        return(
                                            <div className="grid grid-cols-11 gap-4 mt-4">
                                                <InputSelect
                                                    margintopClassName={'mt-5px'}
                                                    isDisabled={index === 0}
                                                    selected={tr}
                                                    loading={false}
                                                    classNameContainer={'col-span-2'}
                                                    options={optionsTurnos}
                                                    onChangeSelect={(e: any) => onSelectTurnos(e, tr, dt, index, indexPrimario)}
                                                    placeholder={'Turno'}
                                                />
                                                <div className="col-span-1">
                                                    <div className={'flex w-full'}>
                                                        <button
                                                            onClick={() => addHorarioTurnosProfessor(indexPrimario, index)}
                                                            className={'w-full h-[2.56rem] flex items-center justify-center'}
                                                        >
                                                            <MdOutlineAddCircleOutline color={'#30694A'} size={25}/>
                                                        </button>
                                                        {
                                                            index === 0 ? '':
                                                                <button
                                                                    onClick={() => removeHorarioTurnosProfessor(indexPrimario, index)}
                                                                    className={'w-full h-[2.56rem] flex ml-1 items-center justify-center'}
                                                                >
                                                                    <IoIosRemoveCircleOutline  color={'#e30a14'} size={25}/>
                                                                </button>
                                                        }

                                                    </div>
                                                </div>
                                                <Input
                                                    type={"number"}
                                                    label=""
                                                    key={'quantidade_alunos'+index}
                                                    className="col-span-2"
                                                    value={tr?.quantidade_alunos}
                                                    setValue={v => handleChangeHorarios(v, 'quantidade_alunos', index, indexPrimario)}
                                                />
                                                <Input
                                                    type={"time"}
                                                    label=""
                                                    key={'duracao'+index}
                                                    className="col-span-2"
                                                    value={tr?.duracao}
                                                    setValue={v => handleChangeHorarios(v, 'duracao', index, indexPrimario)}
                                                />
                                                <Input
                                                    type={"time"}
                                                    label=""
                                                    key={'inicio_aula'+index}
                                                    className="col-span-2"
                                                    value={tr?.inicio_aula}
                                                    setValue={v => handleChangeHorarios(v, 'inicio_aula', index, indexPrimario)}
                                                />
                                                <Input
                                                    type={"time"}
                                                    label=""
                                                    key={'final_aula'+index}
                                                    className="col-span-2"
                                                    value={tr?.final_aula}
                                                    setValue={v => handleChangeHorarios(v, 'final_aula', index, indexPrimario)}
                                                />
                                            </div>
                                        )
                                    }) : <></>
                                }

                            </>
                        )


                    })
                }

            </Modal>
        </>
    )
}