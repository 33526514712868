import InputMask from 'react-input-mask';
import Str from '../../../helpers/Str';
import { BiSearch } from 'react-icons/bi';
import { tw } from '@matheuscaetano/helprs';
import { FaSpinner } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

export interface InputProps {
  className?: string;
  placeholder?: string;
  label?: string;
  error?: string;
  mask?: string | 'cm' | 'kg' | 'longitude' | 'latitude' | 'UF' | 'CEP' | 'CPF' | 'CNPJ' | 'clientCode' | 'phone' | 'cellphone' ;
  value?: string | number;
  inputType?: 'longitude' | 'latitude' | 'UF' | 'CEP' | 'CPF' | 'CNPJ' | 'clientCode' | 'password' | 'email' | 'time';
  setValue?(value: string): void;
  large?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  type?: 'number' | 'date' | 'time';
  min?: number;
  name?: string;
  key?: string;
  required?: boolean;
  tooltip?: string;
  [key: string]: any;
}

const Input = ({
  className,
  value,
  label,
  error,
  setValue,
  mask = '',
  inputType,
  placeholder,
  large,
  hideLabel,
  type,
  name,
  key,
  tooltip,
  required,
  ...rest
}: InputProps) => {
  const finalPlaceholder = 
    inputType === 'longitude' ? 'Longitude' :
      inputType === 'latitude' ? 'Latitude' :
        inputType === 'UF' ? 'UF' :
          inputType === 'CEP' ? 'CEP' :
            inputType === 'CPF' ? 'CPF' :
              inputType === 'CNPJ' ? 'CNPJ' :
                inputType === 'clientCode' ? 'Código de cliente' : 
                  inputType === 'password' ? 'Senha' :
                    inputType === 'email' ? 'email' :
                    placeholder;

  const finalMask = (mask === 'CPF' || inputType === 'CPF') ? '999.999.999-99' : 
    (mask === 'cm') ? '99999,99' : 
      (mask === 'kg') ? '99999,99' : 
        (mask === 'phone') ? '(99) 9999-9999' : 
          (mask === 'cellphone') ? `${value}`.trim().length <= 10 ? '(99) 9999-99999' : '(99) 9 9999-9999' : 
            (mask === 'longitude' || inputType === 'longitude') ? '' : 
              (mask === 'latitude' || inputType === 'latitude') ? '' : 
                (mask === 'CEP' || inputType === 'CEP') ? '99.999-999' : 
                  (mask === 'UF' || inputType === 'UF') ? 'aa' : 
                    (mask === 'clientCode' || inputType === 'clientCode') ? '99.999.999/9999-99' : 
                      (mask === 'CNPJ' || inputType === 'CNPJ') ? '99.999.999/9999-99' : 
                        mask;

  const finalType = inputType === 'password' ? 'password' : type;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleOnChange(event: any): void {
    if (! setValue) return;

    let value = event.target.value as string;

    if (
      mask === 'CPF' || inputType === 'CPF' ||
      mask === 'clientCode' || inputType === 'clientCode' ||
      mask === 'CNPJ' || inputType === 'CNPJ' ||
      mask === 'CEP' || inputType === 'CEP' ||
      mask === 'longitude' || inputType === 'longitude' ||
      mask === 'latitude' || inputType === 'latitude' ||
      mask === 'phone' || mask === 'cellphone'
    ) value = Str.removeNonNumbers(value);

    if (mask === 'cm' || mask === 'kg') {
      value = value.replaceAll(',__', '').replaceAll('_', ''); 
    }

    if (mask === 'UF' || inputType === 'UF') value = value.toUpperCase();

    setValue(value);
  }

  return (
    <label className={Str.tw('w-full', className)}>
      {!hideLabel && <span>
        {label || finalPlaceholder} 
        {required ? <span className='text-red-600'>*</span> : ''}
        {tooltip && (
          <>
            <Tooltip id={tooltip.replaceAll(' ', '-')} />
            <a
              data-tooltip-id={tooltip.replaceAll(' ', '-')}
              data-tooltip-content={tooltip}
              data-tooltip-place="top"
            >
              <span className='mx-1.5 border border-blue-950 rounded-full px-1.5 font-bold'>?</span>
            </a>
          </>
        )}
      </span>}
      <InputMask
        key={key}
        {...rest}
        name={name}
        maskChar="" 
        type={finalType}
        mask={finalMask}
        placeholder={hideLabel ? finalPlaceholder : ''}
        value={(type === 'date' && value?.toString().length === 'XXXX-XX-XX XX:XX:XX'.length) ? value.toString().substring(0, 'XXXX-XX-XX'.length)  : value}
        onChange={handleOnChange}
        className={Str.tw(
          large ? 'py-3' : 'py-2',
          'bg-light border border-border rounded-md outline-none px-3 lg:px-2 w-full',
          'focus:bg-background focus:border-confianca transition uppercase'
        )}
      />
      {!!error?.length && <span className="text-red-600 ">{error}</span>}
    </label>
  );
}

Input.WithSearchButton = ({ searchButton, className, ...rest }: InputProps & {
  searchButton?: {
    disabled?: boolean;
    onClick?: () => void;
    loading?: boolean;
  }
}) => {
  return (
    <div className={Str.tw('relative', className)}>
      <Input {...rest} />

      <button 
          className="absolute top-[25px] right-[2px] h-[40px] w-[40px] flex items-center justify-center transition hover:bg-gray-100 rounded-md" 
          title="Buscar CEP"
          type="button"
          onClick={() => searchButton?.onClick && searchButton?.onClick()}
          disabled={searchButton?.disabled}
      >
          {searchButton?.loading ? <FaSpinner className='animate-spin fill-gray-500' /> : <BiSearch className={tw(`${searchButton?.disabled ? 'fill-gray-300' : 'fill-confianca'}`)} />}
      </button>
  </div>
  )
}

export default Input;
