import dayjs from "dayjs";
import { z } from "zod";
import { UseFormData } from "../../../hooks/useForm"
import { FiPlusCircle } from "react-icons/fi";
import { Aluno, AlunoCurso } from "../../../models"
import { alunoApi, eventoApi } from "../../../services";
import { useCursos } from "../../../hooks";
import Alert from "../../../helpers/Alert";
import {
  Button,
  FormContainer,
  Input,
  RequiredMessage,
  Select,
  Txt
} from "../../../components";
import {
  alunoCursoAmountOfMonths,
  alunoCursoCalcDates,
  alunoCursoCanGetDiscount,
  alunoCursoFinalDate,
  alunoCursoMonthlyValue,
  alunoCursoTurnosHorarios,
  diasSemanaFromProfessor,
  openBase64Pdf
} from "../../../functions";
import { useEffect, useState } from "react";

export type Step1Props = UseFormData<Aluno> & {
  canEdit?: boolean;
  nextStep: () => void;
}

export default function Step1(props: Step1Props) {

  async function handleNext() {
    if (! validateForm()) return;

    loadDatas();
    props.nextStep();
  }

  async function loadDatas() {
    props.form!.aluno_cursos!.forEach((ac, i) => {
      if (ac.data && !props.canEdit) return;

      loadCursoDates(ac, i);
    });
  }

  async function loadCursoDates(ac: AlunoCurso, index: number) {
    let dates = alunoCursoCalcDates(ac).sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1);
    console.clear();

    const events = await eventoApi.loadCourseEvents([ac.curso_id ?? ac.curso?.id], dates[0].date, dates[dates.length - 1].date);

    for (let i = 0; i < dates.length; i++) {
      if (i > 0) {
        if (dayjs(dates[i].date).isSame(dayjs(dates[i - 1].date)) || dayjs(dates[i].date).isBefore(dayjs(dates[i - 1].date))) {
          dates[i].date = dayjs(dates[i - 1].date).add(1, 'week').format('YYYY-MM-DD');
        }
      }

      for (const event of events) {
        const eventStart = dayjs(event.inicio.split(' ')[0]);
        const eventEnd = dayjs(event.fim.split(' ')[0]);

        while (
          !event.considerar_contagem_aulas &&
          (eventStart.isSame(dayjs(dates[i].date)) || eventStart.isBefore(dayjs(dates[i].date)))
          && (eventEnd.isSame(dayjs(dates[i].date)) || eventEnd.isAfter(dayjs(dates[i].date)))
        ) {
          dates[i].date = dayjs(dates[i].date).add(1, 'week').format('YYYY-MM-DD');
        }
      }
    }

    // Order events dates
    dates = dates.sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1);

    console.log('DATES', dates);

    const f = props.form;
    f.aluno_cursos![index] = { ...ac, datas_aulas: dates };
    props.setForm({ ...f });
  }

  function validateForm() {
    const validationRules = z.object({
      id: z.number().min(1),
      aluno_cursos: z.object({
          pagamento_dia: z.number().min(1),
          pagamento_forma: z.string().min(1),
          pagamento_tipo_notificacao: z.string().min(1),
          professor_temp: z.object({}),
          curso_id: z.number().min(1),
          dias_semana_temp: z.array(z.any()).nonempty(),
          horarios: z.array(z.any()).nonempty(),
          planos: z.array(z.string()).nonempty(),
      }).array()
  });

    return props.validateWithZod(validationRules) ;
  }

  async function downloadContract() {
    const envelopeId = props.form?.contratos![0]?.envelope_id;
    Alert.await('Baixando Contrato...');
    const { data } = await alunoApi.downloadSigned(envelopeId);
    Alert.close();
    openBase64Pdf(data);
  }

  return (
    <FormContainer title="Cadastrar cursos para aluno">
      <AlunoSelector {...props} />

      <div className="grid gap-6 md:grid-cols-2">
        {props.form.aluno_cursos!.map((alunoCurso, i) => (
          <AlunoCursoForm 
            {...props}
            key={i} 
            index={i} 
            alunoCurso={alunoCurso} 
          />
        ))}

        <AddCursoBtn {...props} />
      </div>

      <footer className="col-span-full flex flex-col md:flex-row items-center md:items-end md:justify-between mt-6">
          <RequiredMessage />

          {props.canEdit || !props.form.contratos?.length ? (
            <Button 
              loading={props.loading} 
              variant="confianca"
              onClick={handleNext}
            >
              Próximo
            </Button>
          ) : (
            <Button 
              loading={props.loading} 
              variant="confianca"
              onClick={downloadContract}
            >
              Visualizar Contrato
            </Button>
          )}
      </footer>
    </FormContainer>
  )
}

const AlunoSelector = (props: Step1Props) => {
  const [alunos, setAlunos] = useState<Aluno[]>([]);

  // async function searchStudents(q: string) {
  //   const { data, isError } = await alunoApi.all({ like: ['nome', q] });
  //   if (isError) return [];
  //   return data.data.map((opt: any) => ({ value: opt.id, label: opt.nome })); 
  // }

  async function load() {
    const { data, isError } = await alunoApi.all();
    if (isError) return;
    setAlunos(data.data);
  }

  useEffect(() => {
    load();
  }, [])

  return (
    <label>
        Aluno
        <Select
            isDisabled={!props.canEdit}
            value={props.form.id}
            setValue={(value: number) => {
              props.setForm({ ...props.form, id: value });
            }}
            options={alunos.map((opt: any) => ({ value: opt.id, label: opt.nome }))}
            // onChange={(value: any) => {
            //   props.setForm({ ...props.form, id: value.value, nome: value.label });
            // }}
            className="col-span-full"
            // loadOptions={searchStudents}

        />
        <span className="text-red-600 ">{props.errors.id}</span>
    </label>
  )
}

const AlunoCursoForm = (props: Step1Props & { index: number, alunoCurso: Partial<AlunoCurso> }) => {
  const { cursosAsSelectOptions, cursos } = useCursos();
  const errors =  getErrors();
  const [paymentMonths, setPaymentMonths] = useState<string[]>();

  useEffect(() => {
    if (!props.alunoCurso.data) return;
    const data = typeof props.alunoCurso.data === 'string' ? JSON.parse(props.alunoCurso.data) : props.alunoCurso.data;

    if (data.meses_pagamento) {
      setPaymentMonths(data.meses_pagamento);
    } else {
      getPaymentMonths(props.alunoCurso);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.id]);

  useEffect(() => {
    if (!props.alunoCurso.id) getPaymentMonths(props.alunoCurso);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.alunoCurso.data_inicio, props.alunoCurso.planos]);

  function getErrors(): any {
    if (! props.errors.aluno_cursos) return {};
    if (Object.keys((props.errors.aluno_cursos as any))?.length < props.index + 1) return {};
    return (props.errors.aluno_cursos as any)[props.index] ?? {};
  }

  function onChange(alunoCurso: Partial<AlunoCurso>, field: string, newValue: any) {
    if (field === 'curso_id') { 
        (alunoCurso as any).curso_id = newValue;
        (alunoCurso as any).curso = cursos.find(c => c.id === newValue) ?? undefined;
    } else if (field === 'professor_temp') { 
        const teacher = alunoCurso.curso?.professores?.find(p => p.id === newValue)!;
        alunoCurso.professor_temp = teacher;
    } else if (field === 'horarios') {
        (alunoCurso as any).horarios = [];

        newValue.forEach((salaTurnoId: number) => {
            (alunoCurso as any).horarios.push({
                salas_turnos_id: salaTurnoId
            });
        });
    } else if (field === 'pagamento_dia') {
      (alunoCurso as any)[field] = newValue;

      alunoCurso.meses_pagamento = (alunoCurso.meses_pagamento ?? []).map(item => {
        const splitted = item.split('-');
        const year = splitted[0];
        const month = splitted[1];
        const day = `${newValue}`.length === 1 ? `0${newValue}` : `${newValue}`;
        return `${year}-${month}-${day}`;
      })

      setPaymentMonths((paymentMonths ?? []).map(item => {
        console.log(item);
        const splitted = item.split('-');
        const year = splitted[0];
        const month = splitted[1];
        const day = `${newValue}`.length === 1 ? `0${newValue}` : `${newValue}`;
        return `${year}-${month}-${day}`;
      }));
    } else {
        (alunoCurso as any)[field] = newValue;
    }

    props.setForm({ 
        ...props.form, 
        aluno_cursos: props.form.aluno_cursos!.map((ac) => (ac.curso?.id === alunoCurso.id && ac.dobra === alunoCurso.dobra) ? alunoCurso : ac)
    } as any);
  }

  function getPaymentMonths(alunoCurso: Partial<AlunoCurso>) {
      if (!alunoCurso.data_inicio || !alunoCurso.planos?.length) return [];

      const months: string[] = [];

      let month = dayjs(alunoCurso.data_inicio);

      Array(alunoCursoAmountOfMonths(alunoCurso)).fill(0).forEach(() => {
          months.push(month.format('YYYY-MM-DD'));
          month = month.add(1, 'month');
      });
      setPaymentMonths(months);
      alunoCurso.meses_pagamento = months;
  }

  function onMonthChange(value: string | string, index: number) {
    const newPaymentMonths = paymentMonths?.map((month: any, i) => {
      if (i === index) return value;
      return month;
    })

    const acs = props.form!.aluno_cursos!;
    acs[props.index].meses_pagamento = newPaymentMonths as any;
      
    props.setForm({
      ...props.form,
      aluno_cursos: acs
    });

    setPaymentMonths(newPaymentMonths);
  }


  return (
    <section className="grid grid-cols-6 gap-4 mt-8">
        <Select
            required
            disabled={!props.canEdit}
            label={`Curso`}
            className="col-span-full"
            error={errors.curso_id}
            value={props.alunoCurso.curso_id}
            options={cursosAsSelectOptions}
            setValue={(v: number) => onChange(props.alunoCurso, 'curso_id', v)}
        />

        <Select
            required
            disabled={!props.canEdit}
            label="Professor"
            className="col-span-full md:col-span-4"
            error={errors.professor_temp}
            value={props.alunoCurso.professor_temp?.id}
            options={props.alunoCurso.curso?.professores?.map((professor) => {return {value:professor.id, label: professor.nome}}) ?? []}
            setValue={(v: number) => onChange(props.alunoCurso, 'professor_temp', v)}
        />
    
        <Input
            disabled
            className="col-span-full md:col-span-2"
            label="Categoria"
            value={props.alunoCurso.curso?.categoria?.nome}
        />
        
        <Select
            isMulti
            required
            disabled={!props.canEdit}
            label="Dias(s) da Semana"
            className="col-span-full"
            error={errors.dias_semana_temp}
            value={props.alunoCurso.dias_semana_temp}
            setValue={(v: number) => onChange(props.alunoCurso, 'dias_semana_temp', v)}
            options={diasSemanaFromProfessor(props.alunoCurso.professor_temp)}
        />
        
        <Select
            isMulti
            required
            disabled={!props.canEdit}
            label="Horário"
            className="col-span-full"
            error={errors.horarios}
            value={props.alunoCurso.horarios?.map((h) => h.salas_turnos_id)}
            setValue={(v: number) => onChange(props.alunoCurso, 'horarios', v)}
            options={alunoCursoTurnosHorarios(props.alunoCurso)}
        />
    
        <Select
            required
            disabled={!props.canEdit}
            label="Plano"
            className="col-span-full md:col-span-3"
            value={Array.isArray(props.alunoCurso.planos) ? props.alunoCurso.planos?.length ? props.alunoCurso.planos[0] : undefined : props.alunoCurso.planos}
            setValue={(v: number) => onChange(props.alunoCurso, 'planos', Array.isArray(v) ? v : [v])}
            error={errors.planos}
            options={[
                {value: 'Mensal', label: 'Mensal'}, 
                {value: 'Trimestral', label: 'Trimestral'}, 
                {value: 'Semestral', label: 'Semestral'}, 
                // {value: 'Outro', label: 'Outro'}
            ]}
        />
    
        <Input
            disabled={!props.canEdit}
            label="Carga Horária"
            className="col-span-full lg:col-span-3"
            value={props.alunoCurso.carga_horaria}
            setValue={(v) => onChange(props.alunoCurso, 'carga_horaria', v)}
            error={errors.carga_horaria}
        />
    
        <Input 
            required
            disabled={!props.canEdit}
            label="Início"
            type="date"
            data-inicio={props.alunoCurso.id}
            className="col-span-full lg:col-span-3"
            value={props.alunoCurso.data_inicio}
            setValue={(v) => onChange(props.alunoCurso, 'data_inicio', v)}
            error={errors.data_inicio}
        />
    
        <Input 
            disabled
            label="Final"
            className="col-span-full lg:col-span-3"
            value={alunoCursoFinalDate(props.alunoCurso)}
        />

        {!!paymentMonths?.length && <div className="col-span-full">
            <Txt>Datas Pagamento <small>(O dia é definido no campo "dia" abaixo)</small></Txt>
            <div className="grid grid-cols-7 gap-4">
                {paymentMonths.map((month, index) => (
                    <Input 
                      className="col-span-2"
                      type="date"
                      value={month}
                      setValue={(value) => onMonthChange(value, index)}
                    />
                ))}
            </div>
        </div>}
    
        <Input 
            disabled
            type="number"
            label="Valor/Mês"
            className="col-span-full lg:col-span-2"
            value={alunoCursoMonthlyValue(props.alunoCurso)}
        />
    
        <Input 
            disabled={alunoCursoCanGetDiscount(props.form)}
            label="% Desconto"
            className="col-span-full lg:col-span-2"
            type="number"
            value={props.alunoCurso.porcentagem_desconto}
            setValue={(v: string) => onChange(props.alunoCurso, 'porcentagem_desconto', v)}
        />
    
        <Input 
            disabled
            type="number"
            label="Valor/Mês Final"
            className="col-span-full lg:col-span-2"
            value={alunoCursoMonthlyValue(props.alunoCurso, true)}
        />

        <Select 
            required
            disabled={!props.canEdit}
            label="Dia"
            className="col-span-full lg:col-span-2"
            value={props.alunoCurso.pagamento_dia}
            error={errors.pagamento_dia}
            setValue={(v: string) => onChange(props.alunoCurso, 'pagamento_dia', v)}
            options={[
                {value: 1,   label: 1},
                {value: 2,   label: 2},
                {value: 3,   label: 3},
                {value: 4,   label: 4},
                {value: 5,   label: 5},
                {value: 6,   label: 6},
                {value: 7,   label: 7},
                {value: 8,   label: 8},
                {value: 9,   label: 9},
                {value: 10,  label: 10},
            ]}
        />
    
        <Select 
            required
            disabled={!props.canEdit}
            label="Forma Pagamento"
            className="col-span-full lg:col-span-2"
            value={props.alunoCurso.pagamento_forma}
            error={errors.pagamento_forma}
            setValue={(v: string) => onChange(props.alunoCurso, 'pagamento_forma', v)}
            options={[
                {value: 'Pix',      label: 'Pix'},
                {value: 'Boleto',   label: 'Boleto'},
                {value: 'Cartão',   label: 'Cartão'},
                {value: 'Dinheiro', label: 'Dinheiro'},
            ]}
        />
    
        <Select 
            required
            disabled={!props.canEdit}
            label="Notificar"
            className="col-span-full lg:col-span-2"
            error={errors.pagamento_tipo_notificacao}
            value={props.alunoCurso.pagamento_tipo_notificacao}
            setValue={(v: string) => onChange(props.alunoCurso, 'pagamento_tipo_notificacao', v)}
            options={[
                {value: 'WhatsApp', label: 'WhatsApp'},
                {value: 'E-mail',   label: 'E-mail'},
                {value: 'SMS',      label: 'SMS'},
            ]}
        />

        <Select
            disabled={!props.canEdit}
            label="Repasse"
            className="col-span-full"
            value={props.alunoCurso.repasse}
            setValue={(v: string) => onChange(props.alunoCurso, 'repasse', v)}
            error={errors.repasse}
            options={[
                
                {
                    value: 'espaco',
                    label: 'Espaço'
                },
                {
                    value: 'professor',
                    label: 'Professor'
                },
            ]}
        />

        <Input
          disabled={!props.canEdit}
          label="Observação"
          className="col-span-full"
          value={props.alunoCurso.obs}
          setValue={(v) => onChange(props.alunoCurso, 'obs', v)}
          error={errors.obs}
        />
    </section>
  );
}

const AddCursoBtn = (props: Step1Props) => {
  function addCurso() {
    props.setForm({ 
      ...props.form, 
      aluno_cursos: [
        ...props.form.aluno_cursos!, 
        { curso_id: undefined }
      ] as AlunoCurso[]
    });
  }

  return !props.canEdit ? <></> : (
    <div className="flex flex-col items-center justify-center ">
      <button className="flex flex-col items-center justify-center hover:animate-pulse" type="button" onClick={addCurso}>
        <FiPlusCircle color={'#30694A'} size={30}/>
        <span>Adicionar Curso</span>
      </button>
    </div>
  );
}
