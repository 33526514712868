import PageContainer from "../../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import Button from "../../../components/button/Button";
import TabsCategoria from "../../../components/tabsCategorias";
import turnosApi from "../../../services/turnosApi";
import cursosApi from "../../../services/cursosApi";
import categoriasApi from "../../../services/categoriaApi";
import {useNavigate} from "react-router-dom";
import diasApi from "../../../services/diasApi";

export default function TabelaHorarios() {
   const navigate = useNavigate();
   const [categorias, setCategorias]: any[] = useState([]);

   async function getData(){
      const [categoriasData] = await Promise.all([
         categoriasApi.listAll()
      ]);
      if (!categoriasData.isError){
         setCategorias(categoriasData.data);

         // @ts-ignore
         //setCategoriaId(categoriasData.data[0]['id']);
      }
   }

   useEffect(() => {getData()}, [])

   const selectCategoriaId = (datacategoriaId: number) => {
   }

   const data = [
      {
         sala: 'Sala 01',
         dias: [
            {
               semana: 'Terça',
               turnos: [
                  {
                     nome: 'Mat',
                     horarios: [
                        {
                           inicio: '07:00',
                           fim: '08:00'
                        },
                        {
                           inicio: '08:00',
                           fim: '09:00'
                        },
                        {
                           inicio: '09:00',
                           fim: '10:00'
                        },
                        {
                           inicio: '10:00',
                           fim: '11:00'
                        }
                     ]
                  },
                  {
                     nome: 'Vesp',
                     horarios: [
                        {
                           inicio: '12:00',
                           fim: '13:00'
                        },
                        {
                           inicio: '13:00',
                           fim: '14:00'
                        },
                        {
                           inicio: '15:00',
                           fim: '16:00'
                        },
                        {
                           inicio: '17:00',
                           fim: '18:00'
                        },
                     ]
                  },
                  {
                     nome: 'Not',
                     horarios: [
                        {
                           inicio: '18:00',
                           fim: '19:00'
                        },
                        {
                           inicio: '19:00',
                           fim: '20:00'
                        },
                        {
                           inicio: '20:00',
                           fim: '21:00'
                        }
                     ]
                  }
               ]
            },
            {
               semana: 'Quarta',
               turnos: [
                  {
                     nome: 'Mat',
                     horarios: [
                        {
                           inicio: '07:00',
                           fim: '08:00'
                        },
                        {
                           inicio: '08:00',
                           fim: '09:00'
                        },
                        {
                           inicio: '09:00',
                           fim: '10:00'
                        },
                        {
                           inicio: '10:00',
                           fim: '11:00'
                        }
                     ]
                  },
                  {
                     nome: 'Vesp',
                     horarios: [
                        {
                           inicio: '12:00',
                           fim: '13:00'
                        },
                        {
                           inicio: '13:00',
                           fim: '14:00'
                        },
                        {
                           inicio: '15:00',
                           fim: '16:00'
                        },
                        {
                           inicio: '17:00',
                           fim: '18:00'
                        },
                     ]
                  },
                  {
                     nome: 'Not',
                     horarios: [
                        {
                           inicio: '18:00',
                           fim: '19:00'
                        },
                        {
                           inicio: '19:00',
                           fim: '20:00'
                        },
                        {
                           inicio: '20:00',
                           fim: '21:00'
                        }
                     ]
                  }
               ]
            },
            {
               semana: 'Quinta',
               turnos: [
                  {
                     nome: 'Mat',
                     horarios: [
                        {
                           inicio: '07:00',
                           fim: '08:00'
                        },
                        {
                           inicio: '08:00',
                           fim: '09:00'
                        },
                        {
                           inicio: '09:00',
                           fim: '10:00'
                        },
                        {
                           inicio: '10:00',
                           fim: '11:00'
                        }
                     ]
                  },
                  {
                     nome: 'Vesp',
                     horarios: [
                        {
                           inicio: '12:00',
                           fim: '13:00'
                        },
                        {
                           inicio: '13:00',
                           fim: '14:00'
                        },
                        {
                           inicio: '15:00',
                           fim: '16:00'
                        },
                        {
                           inicio: '17:00',
                           fim: '18:00'
                        },
                     ]
                  },
                  {
                     nome: 'Not',
                     horarios: [
                        {
                           inicio: '18:00',
                           fim: '19:00'
                        },
                        {
                           inicio: '19:00',
                           fim: '20:00'
                        },
                        {
                           inicio: '20:00',
                           fim: '21:00'
                        }
                     ]
                  }
               ]
            }

         ]
      },
      {
         sala: 'Sala 02',
         dias: [
            {
               semana: 'Terça',
               turnos: [
                  {
                     nome: 'Mat',
                     horarios: [
                        {
                           inicio: '07:00',
                           fim: '08:00'
                        },
                        {
                           inicio: '08:00',
                           fim: '09:00'
                        },
                        {
                           inicio: '09:00',
                           fim: '10:00'
                        },
                        {
                           inicio: '10:00',
                           fim: '11:00'
                        }
                     ]
                  },
                  {
                     nome: 'Vesp',
                     horarios: [
                        {
                           inicio: '12:00',
                           fim: '13:00'
                        },
                        {
                           inicio: '13:00',
                           fim: '14:00'
                        },
                        {
                           inicio: '15:00',
                           fim: '16:00'
                        },
                        {
                           inicio: '17:00',
                           fim: '18:00'
                        },
                     ]
                  },
                  {
                     nome: 'Not',
                     horarios: [
                        {
                           inicio: '18:00',
                           fim: '19:00'
                        },
                        {
                           inicio: '19:00',
                           fim: '20:00'
                        },
                        {
                           inicio: '20:00',
                           fim: '21:00'
                        }
                     ]
                  }
               ]
            },
            {
               semana: 'Quarta',
               turnos: [
                  {
                     nome: 'Mat',
                     horarios: [
                        {
                           inicio: '07:00',
                           fim: '08:00'
                        },
                        {
                           inicio: '08:00',
                           fim: '09:00'
                        },
                        {
                           inicio: '09:00',
                           fim: '10:00'
                        },
                        {
                           inicio: '10:00',
                           fim: '11:00'
                        }
                     ]
                  },
                  {
                     nome: 'Vesp',
                     horarios: [
                        {
                           inicio: '12:00',
                           fim: '13:00'
                        },
                        {
                           inicio: '13:00',
                           fim: '14:00'
                        },
                        {
                           inicio: '15:00',
                           fim: '16:00'
                        },
                        {
                           inicio: '17:00',
                           fim: '18:00'
                        },
                     ]
                  },
                  {
                     nome: 'Not',
                     horarios: [
                        {
                           inicio: '18:00',
                           fim: '19:00'
                        },
                        {
                           inicio: '19:00',
                           fim: '20:00'
                        },
                        {
                           inicio: '20:00',
                           fim: '21:00'
                        }
                     ]
                  }
               ]
            }
         ]
      }
   ]

   return (
       <PageContainer>
          <div className={'flex flex-col'}>
             <div className={'flex flex-row  p-2 w-full'}>
                <TabsCategoria categorias={categorias} setCategoriaId={selectCategoriaId}/>
                <div className={'flex flex-col'}>
                   <div className={'flex flex-row  p-2 w-full mt-2'}>
                      <Button
                          className={'bg-conhecimento hover:bg-conhecimento-100 text-white'}
                          onClick={() => navigate('/salas')}
                      >
                         Tabela
                      </Button>

                   </div>
                </div>
             </div>
          </div>
          <div className={'mt-5 mb-[5rem]'}>
             <div className={'grid grid-cols-2 gap-2 w-full'}>
                {
                   data.map((dt:any) => {
                     return(
                         <div className={'col-span-1'}>
                            <div className={'border border-2 w-full p-3 h-[31.9rem] relative overflow-y-auto'}>
                               <div className={'flex justify-center'}>
                                  <span className={'text-3xl'}> { dt.sala } </span>
                               </div>

                               <div>
                                  <div className={'grid grid-cols-2 gap-2'}>
                                     {
                                        dt.dias.map((dia: any) => {
                                           return(
                                               <div className={'col-span-1'}>
                                                  <div className={'flex justify-center border border-2 rounded-md'}>
                                                     <span className={'text-2xl'}> { dia.semana } </span>
                                                  </div>
                                                  {
                                                     dia.turnos.map((turno: any) => {
                                                        return(
                                                            <div className={'flex flex-row mb-1 mt-1'}>
                                                               <div className={'flex border rounded-md justify-center items-center w-[5rem] border-2 px-3 mr-1 '}>
                                                                  {turno.nome}
                                                               </div>
                                                               <div className={'flex flex-col w-full'}>
                                                                  {
                                                                     turno.horarios.map((horario: any, indexHorarios: number) => {

                                                                        return(
                                                                            <div className={`flex flex-row w-full mb-1`}>
                                                                               <div className={'flex border rounded-md justify-center items-center h-[2rem] w-full border-2 px-3 mr-1 '}>
                                                                                  {horario.inicio+' - '+horario.fim}
                                                                               </div>
                                                                               <div className={'flex border rounded-md justify-center items-center w-[1rem] border-2 px-3 mr-1 '}>
                                                                                  I
                                                                               </div>
                                                                            </div>
                                                                        )
                                                                     })
                                                                  }
                                                               </div>
                                                            </div>
                                                        )
                                                     })
                                                  }
                                               </div>
                                           )
                                        })
                                     }

                                  </div>

                               </div>

                            </div>
                         </div>
                     )
                   })
                }
                {/*<div className={'col-span-1'}>
                  <div className={'border border-2 w-full p-3'}>
                     <div className={'flex justify-center'}>
                        <span className={'text-3xl'}> Sala01 </span>
                     </div>
                     <div>
                        <div className={'grid grid-cols-2'}>
                           <div className={''}>
                              <div className={'flex justify-center border border-2 rounded-md'}>
                                 <span className={'text-2xl'}> Terça </span>
                              </div>
                           </div>
                           <div className={'border border-2'}>
                              2
                           </div>

                        </div>
                     </div>
                  </div>
                </div>
                <div className={'col-span-1'}>
                  2
                </div>
                <div className={'col-span-1'}>
                   3
                </div>
                <div className={'col-span-1'}>
                   4
                </div>*/}

             </div>
          </div>
       </PageContainer>
   )
}